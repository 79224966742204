<template>
  <div class="information">
    <pageHead title="信息填写"/>
    <van-form @submit="onSubmit" colon>
      <van-field
        v-model="information.userName"
        name="姓名"
        label="姓名"
        placeholder="请输入姓名"
        :rules="[{ required: true}]"
        readonly
      />
      <van-field
        v-model="information.phone"
        type="phone"
        name="电话"
        label="电话"
        placeholder="请输入电话"
        :rules="[{ required: true, validator, message: '请输入正确的手机号'}]"
      />
      <van-field
        v-model="information.area"
        name="选择地区"
        label="选择地区"
        placeholder="省、市、区、街道"
        readonly
        right-icon="arrow-down"
        :rules="[{ required: true}]"
        @click-right-icon='handleChooseArea'
      />
      <van-field
        v-model="information.detailAddr"
        name="详细地址"
        label="详细地址"
        placeholder="小区楼栋/乡村名称"
        :rules="[{ required: true}]"
      />
      <div style="margin: 16px;">
        <van-button round block native-type="submit">提交</van-button>
      </div>
    </van-form>
    <van-popup v-model="showAreaList" position="bottom">
      <van-area title="选择地区" :area-list="areaList" @cancel='handleCancle' @confirm='handleConfirmArea'/>
    </van-popup>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import { areaList } from '@vant/area-data';
import {submitInformation} from '@/api/prize'

export default {
  components: {
    pageHead
  },
  data () {
    return {
      information: {},
      areaList,
      showAreaList: false
    }
  },
  created() {
    this.information.userName = this.$route.query.name
  },
  methods: {
    validator(val) {
      return /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/.test(val)
    },
    async onSubmit() {
      const {area, detailAddr, phone, userName} = this.information
      const {articleId, createTime} = this.$route.query
      const data = {address: `${area}${detailAddr}`, articleId, createTime, phone, userName}
      await submitInformation(data)
      this.$router.back()
    },
    handleChooseArea() {
      this.showAreaList = true
    },
    handleCancle() {
      this.showAreaList = false
    },
    handleConfirmArea(res) {
      this.showAreaList = false
      let area = ''
      res.forEach(item => {
        area += item.name
      })
      this.information.area = area
    }
  }
}
</script>

<style lang='scss' scoped>
.information {
  background-color: #fff;
  height: 100vh;

  .van-form {

    .van-field {
      width: 80%;
      margin: 0 auto;
      border-radius: 18px;
      margin-top: 20px;
      background-color: #FAF2F2;

      ::v-deep .van-field__label {
        width: auto !important;
      }
      ::v-deep .van-field__control{
        -webkit-text-fill-color: $remarkColor !important;
      }
      
    }
    .van-button {
      background-color: $mainColor;
      width: 87px;
      height: 29px;
      margin: 0 auto;
      color: white;
    }
  }
}
</style>
