import request from '@/utils/request'

/**
 * 提交领奖信息
 * @param address
 * @param articleId
 * @param phone
 * @param userName
 * @returns {AxiosPromise}
 */
 export function submitInformation(data) {
  return request({
      url: '/api/zeekr-article/prize/save',
      method: "post",
      data
  })
}